.employee__dashboard {
  display: flex;
  width: 95%;
  margin: 0 auto;
  margin-top: 1rem;
}

.EmployeeList {
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.add-people {
  margin-right: .25em;
  margin-top: -.05em;
  vertical-align: middle;
}

.BaseButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  text-transform: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  box-sizing: border-box;
  background-color: #ff0041;
  color: rgb(255, 255, 255);
  padding: 0.62em 1em;
  border-radius: 0.5em;
  font-weight: 700;
  -webkit-filter: brightness(1);
          filter: brightness(1)
}

.BaseButton:hover {
  -webkit-filter: brightness(1.35);
          filter: brightness(1.35);
}

.EditEmployee,
.DeleteEmployee {
  cursor: pointer;
}

.MuiBox-root {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.MuiDataGrid-columnHeaders {
  width: auto;
  background-color: #000c1d;
  color: #ffffff;
  border-bottom: 1px solid #ffff;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
}

.MuiDataGrid-iconButtonContainer .MuiButtonBase-root {
  color: #ffff;
  border-radius: 0;
  background: transparent;
}

.MuiDataGrid-menuIcon {
  visibility: visible !important;
  color: #ffff;
}

.MuiDataGrid-iconButtonContainer svg {
  opacity: .5 !important;
}

.MuiDataGrid-row,
.MuiDataGrid-cell button {
  color: #007bff;
  font-size: 1rem;
}

.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: flex-start !important;
}

.MuiDataGrid-cell--withRenderer[data-field="editEmployee"],
.MuiDataGrid-cell--withRenderer[data-field="deleteEmployee"] {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}

.EditEmployee,
.DeleteEmployee {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditEmployee svg,
.DeleteEmployee svg {
  margin-right: .25rem;
  vertical-align: middle;
  margin-top: -.15em;
}

.EditEmployee,
.DeleteEmployee {
  color: #007bff;
}

.MuiDataGrid-cell button:hover,
.MuiDataGrid-cell button:hover {
  color: #ff0041;
}

.MuiDataGrid-row:nth-of-type(even) {
  background-color: #f3f3f3;
}

.SearchBar {
  width: 20%;
  position: relative;
  border-radius: 0.5em;
  margin-right: .75em
}

.SearchInput {
  width: 100%;
  background-color: #ccc;
  display: block;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  min-width: 120px;
  flex: 1 1 auto;
  border-radius: .5em;
  height: inherit;
}
.SearchInput input {
  display: block;
  flex: 1 1 100%;
  border: 1px solid rgba(8, 8, 20, 0.1);
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  color: rgb(8, 8, 20);
  border-radius: .5em;
  background-color: #f3f3f3;
  padding: 0.5em;
  height: 3.4em;
}
.SearchInput input:focus {
  outline: none;
  border: 1px solid rgba(8, 8, 20, 0.3);
}
  
.SearchBarIcon {
  padding-right: 1em;
  position: absolute;
  right: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: inherit;
}

/* Group Booking CSS */
.GroupBookingContainer {
  margin-top: 3em;
  display: flex;
  color: #0d6efd;
}

.GroupBookingModal {
  display: flex;
  flex-direction: column;
}

.GroupBookingModal{
  padding: 0px 20px !important;
}
.SeatContainerBox {
  margin-left: 2em;
  display: flex;
  flex-direction: column;
}
.GroupBookingContainer main {
  margin: 0;
  height: 415px;
}
.rs-picker-menu {
  z-index: 2500 !important;
}
.rs-picker-toggle-textbox, .MuiSelect-select {
  padding: 1em !important;
}

.GroupBookingModalContainer {
  position: fixed;
  inset: 0px;
  box-sizing: border-box;
  height: 100%;
  padding-top: 3.2em;
  padding-bottom: 0;
}

.GroupBookingModalOverlay {
  max-width: 920px !important;
}

.GroupBookingModal main {
  margin: 0 !important;
  height: 300px;
}

.DateRangeContainer {
  margin-bottom: .75em;
}

.react-datepicker-popper {
  z-index: 9999;
}

.DateRangeContainer .react-datepicker__close-icon {
  right: 0px;
}

.DateRangeContainer .react-datepicker__input-container .react-datepicker__calendar-icon {
  left: -6px;
  opacity: .75;
}

.react-datepicker__portal {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.react-datepicker__input-container input{
  width: 96%;
  border-radius: 5px;
}