.navbar__links--item {
  border: none !important;
  margin: 0 !important;
  cursor: pointer;
}
.header__logo {
  width: 6em;
  padding-left: 20px;
}

/* .meetingRoom {
  width: 150px;
  height: 30px;
  left: 30px;
  background-color: #db3434;
} */

.meetingRoom {
  width: 220px;
  height: 40px;
  margin-left: 30px;
  background-color: #e0e0e0;
  border-radius: 5px; /* Add rounded corners */
  color: #fff; /* Text color */
  font-size: 1.0rem; /* Font size */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; /* Center text horizontally */
}

.OpsArea{
  width: 220px;
  height: 40px;
  margin-left: 30px;
  background-color: #e0e0e0;
  border-radius: 5px;
  color: #fff; 
  font-size: 1.0rem; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
