.modalContainer {
  position: fixed;
  inset: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  overflow: auto;
  padding-top: 38px;
  padding-bottom: 38px;
  z-index: 1001;
}

.backdrop {
  position: fixed;
  display: block;
  inset: 0px;
  background-color: rgba(8, 8, 20, 0.3);
}

.overlay {
  background-color: rgb(255, 255, 255);
  color: rgb(8, 8, 20);
  transform: translateZ(0px);
  display: flex;
  overflow: hidden;
  width: 90%;
  max-width: 32rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1em 4em;
  border-radius: 1em;
}

.modal {
  max-height: 100%;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: inherit;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  padding-bottom: 0;
}

.modalHeader p {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;
}

.InputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide {
  display: none;
}

.InfoButton {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
          border: none;
          border-radius: 50%;
  cursor: pointer;
}
.InfoButton:hover+.hide {
  display: block;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.589);
  color: #ff0041;
  border-radius: 1px;
  background-color: #e4e2e2;
  position: absolute;
  bottom: 10%;
  margin: 0 20px;
  padding: 10px;
  z-index: 2000;
}

.IconButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex: 0 0 auto;
  text-align: center;
  background-color: transparent;
  color: rgb(255, 255, 255);
  width: 3.2rem;
  height: 3.2rem;
  padding: 0px;
  border: none;
  cursor: pointer;
  z-index: 20000;
}

.modalHeader svg {
  color: #ccc;
}

.modal .content {
  padding: 10px 20px;
  font-size: 1.2rem;
}

.modal .footer {
  padding: 10px 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
}

.footer-button {
  border: 0;
  background: #ff0041;
  border-radius: 5px;
  font-size: 1.2rem;
  line-height: 1;
  color: #fff;
  text-transform: capitalize;
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 2em;
  padding: 0px 0.5em;
  transition: all 0.19s ease 0s;
  cursor: pointer;
  -webkit-filter: brightness(.8);
  filter: brightness(.8);
}

.delete-button {
  background-color: #ff0041;
}

.footer-button:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

.cancel-button {
  background: #6c757d;
}

.cancel-button:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

.BaseInput {
  display: block;
}

.BaseInput label {
  display: block;
  font-weight: 700;
  margin-bottom: .5em;
}

.BaseInput input {
  display: block;
  color: inherit;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  width: 100%;
  padding: .62em 0;
  box-sizing: border-box;
}

.BaseInput input:focus {
  outline: none;
  border-bottom: 1px solid #ff0041;
}

.BaseInput input::-webkit-input-placeholder {
  font-weight: 400;
  color: #ccc
}

.BaseInput input::-moz-placeholder {
  font-weight: 400;
  color: #ccc
}

.BaseInput input:-ms-input-placeholder {
  font-weight: 400;
  color: #ccc
}

.BaseInput input::-ms-input-placeholder {
  font-weight: 400;
  color: #ccc
}

.BaseInput input::placeholder {
  font-weight: 400;
  color: #ccc
}

.BaseInput select {
  display: block;
  color: inherit;
  background-color: transparent;
  border-color: #ccc;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  width: 100%;
  margin-bottom: .5em;
  box-sizing: border-box;
}

.BaseInput select:focus {
  outline: none;
  border-bottom: 1px solid #ff0041;
}