.nav {
    width: 100%;
    z-index: 1001;
    transition-timing-function: ease-in;
    transition: all 0.5s;
    position: relative;
}

.nav__contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__contents a {
    display: flex;
}

.nav__links {
    display: flex;
    align-items: flex-end;
}

.nav__black {
    background-color: rgba(246, 246, 246, 0.91);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.nav__logo {
    -o-object-fit: contain;
    object-fit: contain;
    width: 8em;
    cursor: pointer;
    padding-left: 2rem;
    padding-right: 2.5em;
}

.nav__logo :hover {
    -webkit-filter: brightness(1.1);
    filter: brightness(1.1);
}

.admin {
    z-index: 10001;
    display: flex;
    align-items: center;
    color: #ff0041;
    font-weight: bold;
    padding-right: 2rem;
    text-transform: uppercase;
}

.admin span {
    margin-top: -.05em;
    margin-left: .25em;
}

.admin #PersonIcon {
    vertical-align: middle;
}

.nav__links ul {
    list-style: none;
    cursor: pointer;
}
.navbar__links--item:first-child a{
  color: #ff0041;
}
.manage-seats {
    color: #0d6efd;
    font-size: 1.2rem;
    padding: 0 20px;
    cursor: pointer;
}

.manage-seats:hover {
    cursor: pointer;
    color: rgb(245, 85, 79);
    -webkit-filter: brightness(1.1);
    filter: brightness(1.1);
    transition: all 0.3s ease;
}

.dropdown {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    color: #0d6efd;
    font-size: 1.2rem;
    padding: 0 20px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    margin-top: .05em;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content li {
    margin-bottom: 1rem;
}

.dropdown-content li:hover {
    cursor: pointer;
    color: rgb(245, 85, 79);
    -webkit-filter: brightness(1.1);
    filter: brightness(1.1);
    padding-left: 10px;
    border-left: 2px solid #0d6efd;
    transition: all 0.3s ease;
}