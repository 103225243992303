.nav__links .MuiButtonGroup-outlined a{
  margin-right: .5em;
}

.nav__links--recruitment {
  align-items: center;
}

#addInterviewee {
  width: 100%;
  padding: 0 2em;
}
#addInterviewee input {
  width: 100%;
  line-height: 2.15;
}

#addInterviewee input::-webkit-input-placeholder {
  font-weight: 400;
  color: #ccc;
  font-style: italic;
}
#addInterviewee input::-moz-placeholder {
  font-weight: 400;
  color: #ccc;
  font-style: italic;
}
#addInterviewee input:-ms-input-placeholder {
  font-weight: 400;
  color: #ccc;
  font-style: italic;
}
#addInterviewee input::-ms-input-placeholder {
  font-weight: 400;
  color: #ccc;
  font-style: italic;
}
#addInterviewee input::placeholder {
  font-weight: 400;
  color: #ccc;
  font-style: italic;
}
svg[data-testid="ArrowDownwardIcon"] {
  color: #fff !important;
}

.CopyLinkButton:disabled {
  opacity: .5;
  background:#ccc;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.verifyEmailButton {
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(25, 118, 210);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  
}